<template>
  <main>
    <package-header title="Applications" sub-title="12764 Smith"></package-header>

    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-8 col-md-10">

          <router-link class="d-inline-block mb-3" to="/pages/applications">
            <svg class="icon icon-arrow_cta_back mr-1"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta_back"></use></svg>
            Return to Applications
          </router-link>

          <h2 class="mb-4">Select an application to add to your package</h2>

          <div class="card mb-4 pt-1 pb-0 px-md-3">
            <div class="card-body">
              <div class="row mb-3">
                <div class="col">
                  <label for="searchApplication">Search application types, key words, natures of interest, etc.</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <div class="input-group-text">
                        <svg class="icon icon-search"><use xlink:href="/icons/symbol-defs.svg#icon-search"></use></svg>
                      </div>
                    </div>
                    <input type="text" class="form-control border-left-0" id="searchApplication">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ul class="list-inline text-right">
            <li class="list-inline-item">
              <button class="btn btn-sm btn-link" @click="toggleNames">
                <span v-if="names">Hide</span>
                <span v-else>Show</span>
                old names
              </button>
            </li>
            <li class="list-inline-item">
              <button class="btn btn-sm btn-link" @click="toggleCommon">
                <span v-if="common">Hide</span>
                <span v-else>Show</span>
                common applications
              </button>
            </li>
          </ul>

          <div class="accordion mb-3 border-top">

            <!-- Collapse 1 -->
            <div class="border-bottom">
              <button class="btn collapse-btn py-2" data-toggle="collapse" data-target="#frequentCollapse" aria-expanded="true" aria-controls="frequentCollapse">
                <svg class="icon icon-arrow_right collapse-icon"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_right"></use></svg>
                <strong>Frequently Used</strong>
              </button>

              <div id="frequentCollapse" class="collapse show" aria-labelledby="frequentCollapseHeading">
                <ul class="list-group list-group-indented mb-0">
                  <li class="list-group-item">
                    <div class="d-flex justify-content-between align-items-center">
                      <h5 class="mb-0">
                        Transfer Title
                        <small v-show="names"> - Old name</small>
                      </h5>
                      <button class="btn btn-tertiary btn-sm" @click="addApplication('Transfer Title')">Add</button>
                    </div>
                    <div v-show="common">
                      Common Applications
                    </div>
                  </li>
                  <li class="list-group-item">
                    <div class="d-flex justify-content-between align-items-center">
                      <h5 class="mb-0">
                        Mortgage
                        <small v-show="names"> - Old name</small>
                      </h5>
                      <button class="btn btn-tertiary btn-sm" @click="addApplication('Mortgage')">Add</button>
                    </div>
                    <div v-show="common">
                      Common Applications
                    </div>
                  </li>
                  <li class="list-group-item">
                    <div class="d-flex justify-content-between align-items-center">
                      <h5 class="mb-0">
                        Release Charge
                        <small v-show="names"> - Old name</small>
                      </h5>
                      <button class="btn btn-tertiary btn-sm" @click="addApplication('Release Charge')">Add</button>
                    </div>
                    <div v-show="common">
                      Common Applications
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <!-- Collapse 2 -->
            <div class="border-bottom">
              <button class="btn collapse-btn collapsed py-2" data-toggle="collapse" data-target="#releasesCollapse" aria-expanded="false" aria-controls="releasesCollapse">
                <svg class="icon icon-arrow_right collapse-icon"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_right"></use></svg>
                <strong>Releases</strong>
              </button>

              <div id="releasesCollapse" class="collapse" aria-labelledby="releasesCollapseHeading">
                <ul class="list-group list-group-indented mb-0">
                  <li class="list-group-item">
                    <div class="d-flex justify-content-between align-items-center">
                      <h5 class="mb-0">
                        Application Type
                        <small v-show="names"> - Old name</small>
                      </h5>
                      <button class="btn btn-tertiary btn-sm" @click="addApplication('Application type')">Add</button>
                    </div>
                    <div v-show="common">
                      Common Applications
                    </div>
                  </li>
                  <li class="list-group-item py-0">

                    <button class="btn collapse-btn collapsed py-2" data-toggle="collapse" data-target="#nestedCollapse" aria-expanded="false" aria-controls="nestedCollapse">
                      <svg class="icon icon-arrow_right collapse-icon"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_right"></use></svg>
                      <strong>Nested Collapse</strong>
                    </button>

                    <div id="nestedCollapse" class="collapse" aria-labelledby="nestedCollapseHeading">
                      <ul class="list-group list-group-indented mb-0">
                        <li class="list-group-item">
                          <div class="d-flex justify-content-between align-items-center">
                            <h5 class="mb-0">
                              Application Type
                              <small v-show="names"> - Old name</small>
                            </h5>
                            <button class="btn btn-tertiary btn-sm" @click="addApplication('Application Type')">Add</button>
                          </div>
                          <div v-show="common">
                            Common Applications
                          </div>
                        </li>
                      </ul>
                    </div>

                  </li>
                </ul>
              </div>
            </div>

            <!-- Collapse 3 -->
            <div class="border-bottom">
              <button class="btn collapse-btn py-2 collapsed" data-toggle="collapse" data-target="#group2Collapse" aria-expanded="false" aria-controls="group2Collapse">
                <svg class="icon icon-arrow_right collapse-icon"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_right"></use></svg>
                <strong>Group 2</strong>
              </button>

              <div id="group2Collapse" class="collapse" aria-labelledby="group2CollapseHeading">
                <ul class="list-group list-group-indented mb-0">
                  <li class="list-group-item">
                    <div class="d-flex justify-content-between align-items-center">
                      <h5 class="mb-0">
                        Application Type
                        <small v-show="names"> - Old name</small>
                      </h5>
                      <button class="btn btn-tertiary btn-sm" @click="addApplication('Application Type')">Add</button>
                    </div>
                    <div v-show="common">
                      Common Applications
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="bg-medium py-2 fixed-bottom" v-if="applicationAdded">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-8 d-sm-flex justify-content-between align-items-center">
              <div class="mr-3 mb-2 mb-sm-0">
                <svg class="icon icon-check text-success"><use xlink:href="/icons/symbol-defs.svg#icon-check"></use></svg>
                {{ applicationSelected }} has been successfully added to your package.
              </div>
              <button class="btn btn-primary">Finish</button>
            </div>
          </div>
        </div>
      </div>

      <!-- Scroll to top -->
      <a href="#top" class="d-block float-right d-print-none scroll-to-top">
        <svg class="icon icon-arrow_upward"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_upward"></use></svg>Back to Top
      </a>
    </div>

    <help-panel title="Add Applications"></help-panel>
  </main>
</template>

<script>

import PackageHeader from '../../components/PackageHeader.vue'
import HelpPanel from '../../components/HelpPanel.vue'

export default {

  components: {
    'package-header': PackageHeader,
    'help-panel': HelpPanel
  },

  data () {
    return {
      applicationAdded: false,
      applicationSelected: '',
      names: false,
      common: false
    }
  },

  methods: {
    addApplication: function(name){
        this.applicationAdded = true;
        this.applicationSelected = name;
    },
    toggleNames: function(){
        this.names = !this.names;
    },
    toggleCommon: function(){
        this.common = !this.common;
    }
  }
}
</script>

